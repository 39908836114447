import mapboxgl from 'mapbox-gl';
import * as ajax from './ajax';
import * as mapData from './mapData';
import * as images from './images';
import * as env from './env';
import * as get from './get';
import * as vis from './visibility';
import onPage from './onPage';
import SculptureIcon from '../images/sculpture.svg';
import CloseIcon from '../images/close-icon.png';
import mySlideshow from './mySlideshow';
import {JsRoutesRails,SopRoutes} from './routes';


// initialize Map var for use throughout file
let map;
let mapContainer;

const LOCATION_TYPES = ['built', 'social', 'natural', 'project']
import pointOfInterestIcon from 'images/map/point-of-interest.png'
import tipsIcon from 'images/map/tips.png'

const LOCATION_ICON_SIZE = [
  'interpolate', ['exponential', 2], ['zoom'],
  9, 0.75,
  16, 1
]

const AMENITY_ICON_SIZE = [
  'interpolate', ['linear'], ['zoom'],
  11.5, 0.15,
  20, 0.9
]

let imageAdded = false;

// SETUP ///////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// INIT MAP
let initMap = function(){
  mapContainer = document.getElementById('map');

  if(mapContainer){
    const mapOptions = getMapOptions();
    map = mapData.setupMap('map', mapOptions);
    if (!mapContainer.classList.contains('no-ctrl')) {
      mapData.addControls(map, 'top-right');
    }
    activateAddToMapButton();

    map.on('load', loadData);
    
    // map.on('click', printData) // DEV
  }
}

// Print Map Data
const printData = () => {
}

const getZoom = () => {
  if(mapContainer.hasAttribute('data-zoom')){
    return mapContainer.getAttribute('data-zoom');
  }
}

const getCenter = () => {
  if(mapContainer.hasAttribute('data-center')){
    const lngLat = mapContainer.getAttribute('data-center');
    // convert lng/lat string to array
    return lngLat.split(', ');
  }
}

// Get map options from HTML
const getMapOptions = () => {
  const mapOptions = {};
  const zoom = getZoom();
  const center = getCenter();

  // This ensures we don't end up with any null values
  if(zoom) mapOptions.zoom = zoom;
  if(center) mapOptions.center = center;

  return mapOptions;
}


const loadData = () => {

  // LOAD IMAGES WON't BE NECESSARY WHEN I UPLOAD SVGs
  // setupImages(); // load Images required by these layers
  setupAmenities();
  setupLocations();
  setupTrails();
  setupPlaces();
  setupVrSurveys();
  setupSculptures();

  // those are the slugs for those two categories we need the custom icons so we are loading them here
  map.loadImage(pointOfInterestIcon, (error, image) => {
    map.addImage('points-of-interest', image);
  });

  map.loadImage(tipsIcon, (error, image) => {
    map.addImage('warnings-access-tips', image);
  });
}


// LAYERS ////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// refactor eventually to avoid this nonsense
const setImageSource = (imageName) => {
  if(env.whatEnv() === 'production'){
    return images.mapImages[imageName]
  } else {
    return `assets/map/${imageName}.png`;
  }
}


const setupCursor = (layer) => {
  const pointerCursor = () => { map.getCanvas().style.cursor = 'pointer' };
  const stdCursor = () => { map.getCanvas().style.cursor = '' };
  map.on('mouseenter', layer, pointerCursor);
  map.on('mouseleave', layer, stdCursor);
}


const setLayerTitleStyle = (layer) => {
  let styles = { amenities: 'p', locations: 'h6' };
  if(layer in styles) return styles[layer];
  return 'p';
}

const amenityPopup = (layer, props) => (
  `
    <div class='${layer}-popup'>
      <img
        class="${layer}-icon smr"
        src="${setImageSource(props.icon)}"
        alt="${layer}"
      />

      <p className='nmb'>
        ${props.title}
      </p>
    </div>
  `
)

const locationPopup = (layer, props) => (
  `
    <div class='${layer}-popup'>
      <h6>
        ${props.title}
      </h6>

      <p class='small-font nmb'>
        ${props.description}
      </p>
    </div>
  `
)

const trailPopup = (layer, props) => (
  `
    <div class='${layer}-popup'>
      <h6>
        ${props.title}
      </h6>

      <div class="flex-container align-center">
        <a href="${JsRoutesRails.trail_path({id: props.id})}"/>
          <button class="button expanded">
            View
          </button>
        </a>
      </div>
    </div>
  `
)


const vrSurveyPopup = (layer, props) => (
  `
    <div class='${layer}-popup'>
      <p class='small-font nmb'>
        ${props.description}
      </p>
    </div>
  `
);

const sculpturePopup = (props) => {
  const stories = JSON.parse(props.stories);
  const slides = stories.map((s) => {
    return (
      `
        <div class="story my-slide">
          <div>
            <h4 class="xsmb">${s.name}</h4>
            <p class="smb small-font underline">${props.address}</p>
            <img src="${s.image}" alt="${s.name}" class="story-img contain" />
            <p>${s.description}</p>
          </div>
        </div>
      `
    )
  })

  let markers;
  if(stories.length > 1){
    markers = stories.map((story, i) => {
      return (
        `
          <li class="story-bullet slide-button" data-index="${i}">
            <button>
              <img src="${SculptureIcon}" alt="Sculpture" />
            </button>
          </li>
        `
      )
    })
  }
  
  return (
    `
      <div id="sculpture-popup">
        <img class="close-icon float-right sm-minus mmr-minus " data-toggle="sculpture-popup-container" src="${CloseIcon}" aria-expanded="true" aria-controls="map-info open-map-info add-to-map-button">
        <div class="stories my-slideshow">
          ${slides.join('')}
        </div>

        
        
        <ul class="no-style-list relative">
          ${markers ? markers.join('') : ''}
          <div class="absolute slide-tip">
            ${markers ? `<p class="italic small-font light text-center smb primary">${stories.length} stories. Use the icons to navigate.</p>` : ''}
          </div>
        </ul>
      </div>

      <a href="${props.sop_url}" class="button mmt sml">Read more about ${props.name}</a>
      <a href="/trails?locality_id=${props.locality_id}#trails" class="button mmt sml">Find Trails in ${props.name}</a>
      <a href="/archive?locality_id=${props.locality_id}#items" class="button mmt sml">Search the Archive for ${props.name}</a>
    `
  )
};


const setupPopup = (layer) => {

  map.on('click', layer, (e) => {
    const { properties } = e.features[0];
    const { coordinates } = e.features[0].geometry

    // TAKEN from: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const html = document.createElement('DIV');
    html.classList.add('popup');

    switch(layer){
      case 'amenities':
        html.innerHTML = amenityPopup(layer, properties)
        break;
      case 'trails':
        html.innerHTML = trailPopup(layer, properties)
        break;
      case 'vr-surveys':
        html.innerHTML = vrSurveyPopup(layer, properties)
        break;
      default:
        html.innerHTML = locationPopup(layer, properties)
    }

    // render HTML into Popup
    new mapboxgl.Popup({ offset: 15 })
      .setLngLat(coordinates)
      .setDOMContent(html)
      .addTo(map);

    // easeToClickedMarker(coordinates);
  })
}

const setupLayer = (layer, url = undefined, popup = true, callback = undefined) => {
  url = new URL(url, window.location.origin).href;
  map.addSource(layer, {
    "type": "geojson",
    "data": url
  });

  // Check if the 'places' layer exists
  if (!map.getLayer('places')) {
    map.addLayer({
      "id": layer,
      "type": "symbol",
      "source": layer,
      "layout": {
        "visibility": "visible",
        "icon-image": "{icon}",
        "icon-anchor": "bottom",
        "symbol-z-order": "viewport-y",
        "icon-offset": [5, 5],
      }
    });
  } else {
    map.addLayer({
      "id": layer,
      "type": "symbol",
      "source": layer,
      "layout": {
        "visibility": "visible",
        "icon-image": "{icon}",
        "icon-anchor": "bottom",
        "symbol-z-order": "viewport-y",
        "icon-offset": [5, 5],
      }
    }, 'places');  // Add layer before 'places'
  }

  if (popup) setupPopup(layer);
  setupCursor(layer);
  if (callback) callback();
};

const refreshData = (data) => {
  let url = '/' + data.class_name + 's.json';
  // If a category is provided, add to URL
  // and use this as the layername to refresh
  if(data.category) url += `?category=${data.category}`;
  let layer = data.category ? data.category : data.class_name + 's'

  ajax.getJSON(url, (res) => { map.getSource(layer).setData(res) });
}

const refreshLayerOfCategory = (layer) => {
  const url = `locations.json/?category=${layer}`;
  ajax.getJSON(url, (data) => {
    map.getSource(layer).setData(data);
  })
}


// TODO - I don't like this as it depends upon the checkboxes being set to 1
//        initially. Refactor in future
// used in _filter.html.haml on map#index
const setupToggle = (layer) => {
  console.log('setting up toggle for', layer);
  const body = document.getElementsByTagName('body')[0];
  if(body.classList.contains('map') && body.classList.contains('index')){

    const toggles = document.getElementsByName(layer);
    toggles.forEach((t) => {
      t.addEventListener('change', (e) => {
        const { checked } = e.target;
        console.log(`checked for layer ${layer} - ${checked}`);
        map.setLayoutProperty(layer, 'visibility', checked ? 'visible' : 'none');
        console.log(`visibility for layer ${layer} should be ${checked ? 'visible' : 'none'} but is ${map.getLayoutProperty(layer, 'visibility')}`);
      })
    })
  }
}

// LOCATIONS  //////////////////////////////////////////////////////////////////


// build query string for URL from an object of parameters
// e.g { category: 'built'} will produce '?category=built;'
const buildQuery = (params) => {
  let query = '?'; // query params require ? prefix
  for(var k in params){
    if(params.hasOwnProperty(k))query += `${k}=${params[k]};`
  }
  return query;
}


// Derive a layer name from the first given parameter in an object.
// For example, { category: 'built', locality: 'Dalry' } returns 'built'
const getLayerFromParams = (params) => {
  const firstEntry = Object.keys(params)[0];
  return params[firstEntry]
}


// Create a Layer of locations from parameters
const setupLocationsSubLayer = (params) => {
  const query = buildQuery(params); // e.g '?category=built' to suffix URL
  const layer = getLayerFromParams(params); // layer name, e.g 'built'

  // Create Layer from url
  setupLayer(layer, `locations.json/${query}`);

  // Style layer
  // map.setLayoutProperty(layer, 'icon-allow-overlap', true);
  map.setLayoutProperty(layer, 'icon-size', 0.5);
  map.setLayoutProperty(layer, 'icon-allow-overlap', true);
  setupToggle(layer);
}

// Create Category Locations Layer -
// Look for data-categories on #map element and create layer for it
// We use an iterator here as multiple categories are shown on 1 map
const renderLocationsByCategories = () => {
  const cats = mapContainer.getAttribute('data-categories');
  if(cats) {
    cats.split(', ').forEach(c => setupLocationsSubLayer({category:c}));
  }
}


// Create Locality Locations Layer -
// Look for data-locality-id on #map element and create layer for it
// We use ID here, as only 1 Locality layer is shown at any 1 time.
// Also, the Locality names often contain spaces, creating parsing issues.
// Flag parameter used by Location scope to filter Locations shown
const renderLocationsByLocality = () => {
  const loc = mapContainer.getAttribute('data-locality-id');
  if(loc) setupLocationsSubLayer({locality_id:loc, flag:true});
}


// Add Locations to the map, either by Category or Locality
const setupLocations = () => {
  if(mapContainer.classList.contains('locations')){
    renderLocationsByCategories();
    renderLocationsByLocality();
  }
}


// Link 'places' (and Barrmill in 'Village-Hamlets') to SOP pages
const setupPlaces = () => {
  if(mapContainer.hasAttribute('data-places')){

    // get the Places layer, add on('click' to it)
    const places = map.getLayer('places');
    if(places){
      setupPlacesLinks()
      setupCursor('places');
    }

    // Barrmill is the only place in the Village-Hamlets layer
    // that also has an SOP page. Hence we do the same for just Barrmill.
    setupBarrmill()
  }
}

const setupPlacesLinks = () => {
  map.on('click', 'places', (e) => {
    // get the name of the thing that has been clicked on,
    // select thr url and go there
    const name = e.features[0].properties.name;
    const url = SopRoutes[name];
    if(url) window.location.href = url;
  })
}


const setupBarrmill = () => {
  const villageHamlets = map.getLayer('Village-Hamlets');

  if(villageHamlets){
    map.on('click', 'Village-Hamlets', (e) => {
      if(e.features[0].properties.Name === 'Barrmill'){
        window.location.href = SopRoutes['Barrmill'];
      }
    })
    map.on('mouseenter', 'Village-Hamlets', (e) => {
      if(e.features[0].properties.Name === 'Barrmill'){
        map.getCanvas().style.cursor = 'pointer'
      }
    })
    map.on('mouseleave', 'Village-Hamlets', (e) => {
      if(e.features[0].properties.Name === 'Barrmill'){
        map.getCanvas().style.cursor = ''
      }
    })
  }
}

// Note: The Sculptures layer is quite different as it includes several layers
// and a different sort of Popup.
const setupSculptures = () => {
  if(mapContainer.classList.contains('sculptures')){
  
    map.addSource('sculptures', {
      "type": "geojson",
      "data": `/sculptures`
    });

    map.addLayer({
      "id": 'sculptures-points',
      "type": "symbol",
      "source": 'sculptures',
      "layout": {
        "icon-image": "{icon}",
        "icon-anchor": "bottom",
        "symbol-z-order": "viewport-y",
        "icon-size": 0.2,
        "icon-allow-overlap": true,
      },
      paint: {
        "text-color": "#ffffff",
      },
      'filter': ['==', '$type', 'Point']
    })
    
    map.addLayer({
      "id": 'sculptures-text',
      "type": "symbol",
      "source": 'sculptures',
      "layout": {
        'text-field':['format',
          ['get', 'index'],
          { 'font-scale': 1.1 },
        ],
        'text-variable-anchor': ["bottom"],
        'text-radial-offset':  0.3,
        'text-justify': 'center',
        'text-font': ['Acier Display Solid', 'Open Sans Regular'],
        "text-allow-overlap": true,
        "symbol-z-order": "viewport-y",
      },
      paint: {
        "text-color": "#ffffff",
      },
      'filter': ['==', '$type', 'Point']
    })

    setupCursor('sculptures-text');
    
    map.on('click', 'sculptures-text', (e) => {
      const popup = document.getElementById('sculpture-popup-container');
      if(popup) {
        const info = document.getElementById('sculpture-info-container');
        if(info) info.classList.add('trans');
        popup.innerHTML = sculpturePopup(e.features[0].properties);
        mySlideshow(); // activate slideshow
        vis.fadeIn(popup);
      }
    })
  }
}


const setupVrSurveys = () => {
  if(mapContainer.classList.contains('vr-surveys')){
    setupLayer('vr-surveys');
    map.setLayoutProperty('vr-surveys', 'icon-allow-overlap', true);
  }
}

// AMENITIES ///////////////////////////////////////////////////////////////////

const setupAmenities = () => {
  if(mapContainer.classList.contains('amenities')){
    setupLayer('amenities');
    map.setLayoutProperty('amenities', 'icon-size', AMENITY_ICON_SIZE);
    map.setLayerZoomRange('amenities', 12, 25);
  }
}


const easeToClickedMarker = (coordinates) => {

  const options = {
    center: coordinates
  }

  map.easeTo(options)
}



// TODO - abstract into setupLineLayer
const setupTrails = () => {
  if(mapContainer.classList.contains('trails')){

    map.addSource('trails', {
      "type": "geojson",
      "data": `/trails.json`
    });

    map.addLayer({
      "id": `trails`,
      "type": "line",
      "source": `trails`,
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "miter",
        "line-miter-limit": 2,
      },
      "paint": {
        'line-color': '#E95576',
        "line-width": 3,
        "line-dasharray": [6, 1],
      }
    });

    setupCursor('trails');

    map.on('click', 'trails', (e) => {
      const { properties } = e.features[0];
      const { coordinates } = e.features[0].geometry
      const coords = coordinates[0];
      // FROM: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coords[0]) > 180) {
        coords[0] += e.lngLat.lng > coords[0] ? 360 : -360;
      }

      const html = document.createElement('DIV');
      html.classList.add('popup');
      html.innerHTML = trailPopup('trails', properties);

      // render HTML into Popup
      new mapboxgl.Popup({ offset: 15 })
        .setLngLat(coords)
        .setDOMContent(html)
        .addTo(map);
    })

    setupToggle('trails')
  }
}


// INTERACTIONS ////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


const activateAddToMapButton = () => {
  if(mapContainer.classList.contains('can-add')){
    map.doubleClickZoom.disable();

    // DEPRECATED APPROACH -
    // Previously we used double-click to add things to map
    // However this creates issues on mobile devices
    // setInteractionType();
    // map.on('resize', setInteractionType);

    // Add Listener to Add button
    const addButton = document.getElementById('add-to-map-button');
    if(!addButton) return;

    addButton.addEventListener('click', () => {
      vis.showById('click-map-instruction');
      map.on('click', handleAddToMapClick);
      $(`#add-to-map-button`).foundation('toggle'); // hide button
    })
  }
}


const handleAddToMapClick = (e) => {
  vis.hideById('click-map-instruction'); // hide instructions
  setupForm(e); // open form
  map.off('click', handleAddToMapClick);
}


// NOT WORKING ON IOS
// Set dblclick or touchend event handlers to open form
// const setInteractionType = function(){
//   if(measure.onSmall()){
//     // SMALL or MOBILES
//     // - use customDouble Click ( or Double Tap ) for opening Modal
//     // map.on('dblclick', setupForm);
//     map.on('touchend', (e) => initMobileDoubleTap(e));
//     // map.on('mouseup', (e) => initMobileDoubleTap(e));
//   } else {
//     // LARGE & MED
//     map.off('touchend', (e) => initMobileDoubleTap(e));
//     map.on('dblclick', setupForm);
//   }
// }


// NOT WORKING on IOS
// Detect Double taps
// let lastTouched;
// const initMobileDoubleTap = function(e){
//   const now = new Date().getTime();
//   if(lastTouched){
//     const elapsed = now - lastTouched;
//     if(elapsed > 10 && elapsed < 300) setupForm(e);
//   }
//   lastTouched = now;
// }

// FORM ////////////////////////////////////////////////////////////////////////

// Toggle form and insert geolocation data
const setupForm = function(e){
  togglePopup();
  let { lngLat } = e;
  // insert Geolocation attributes from click event
  let latField = get.elementsWhereIdIncludes('_geolocation_attributes_lat')
  latField.value = lngLat.lat
  let lngField = get.elementsWhereIdIncludes('_geolocation_attributes_lng')
  lngField.value = lngLat.lng
}



const handleFormSubmit = function(e){
  let target = e.target;
  if(target.classList.contains('map-popup-submit')){

    e.preventDefault();
    let formData = extractForm();

    // format url to convert - to _ for POST request, e.g '/vr_surveys'
    let klassName = mapContainer.getAttribute('data-add');
    if(klassName.includes('-')) klassName = klassName.replace('-', '_');


    const url = '/' + klassName + 's.json';

    ajax.sendData(formData, url, (data) => {
      if(data.success){
        clearErrors();
        clearForm();
        onFormSubmitSuccess(data)
        // togglePopup();
        refreshData(data);
        refreshLayerOfCategory(data.category);
      } else {
        const errorsContainer = document.getElementById('errors')
        if(errorsContainer){
          clearErrors();
          errorsContainer.classList.add('white-border', 'mp');
          const { errors } = data;
          createErrorAlert(errorsContainer, errors);
          listErrors(errorsContainer, errors);
          scrollToErrors()
        }
      }
    })
  }
}


const onFormSubmitSuccess = (res) => {
  if(res.class_name === 'vr-survey'){
    togglePopup();
    toggleSuccessMessage();
    // renderVrSuccessMessage();
  } else {
    togglePopup();
  }
}

const toggleSuccessMessage = () => {
  $(`#vr-success`).foundation('toggle');
}

const renderVrSuccessMessage = () => {
  let successDiv = document.getElementById('success-div');
  successDiv.innerHTML = vrSuccessMessage();
}

const togglePopup = () => {
  const klassName = mapContainer.getAttribute('data-add');
  $(`#add-${klassName}`).foundation('toggle');
}


const extractForm = function(){
  let form = document.querySelectorAll('form[data-remote]')[0];
  return new FormData(form);
}

const clearForm = () => {
  let form = document.querySelectorAll('form[data-remote]')[0];
  form.reset();
  clearErrors();
}


const scrollToErrors = () => {
  const errors = document.getElementById('errors');
  errors.scrollIntoView();
}



// ERRORS //////////////////////////////////////////////////////////////////////

const createErrorAlert = (element, errors) => {
  const alert = document.createElement('P');
  const errorCount = document.createTextNode(`${errors.length} errors prevented this being saved.`);
  alert.classList = 'strong white xsmb underline';
  alert.appendChild(errorCount);
  element.appendChild(alert);
}

const listErrors = (element, errors) => {
  errors.forEach(e => {
    const li = document.createElement('LI');
    li.classList.add('bold')
    const text = document.createTextNode(`- ${e}`);
    li.appendChild(text);
    element.appendChild(li);
  })
}

const clearErrors = () => {
  const errors = document.getElementById('errors');
  errors.innerHTML = '';
  errors.classList.remove('white-border');
  errors.classList.remove('mp');
}

const clearLocationsForm = (e) => {
  if(e.target.classList.contains('clear-location-form')) {
    clearForm();
  }
}


const toggleStyle = (e) => {
  const el = e.target;
  // restrict this to Map page
  if(onPage('map')){
    if(el.id === 'satellite-style-switch'){
      map.on('style.load', loadData);
      mapData.setSatStyle(map);
    } else if (el.id === 'map-style-switch'){
      map.on('style.load', loadData);
      mapData.setMapStyle(map);
    }
  }
}


document.addEventListener('turbolinks:load', initMap);
document.addEventListener('click', handleFormSubmit);
document.addEventListener('click', (e) => {
  if(e.target.classList.contains('clear-form')) clearForm();
});
document.addEventListener('click', toggleStyle);